var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-grow-1" },
    [
      _c("search-bar"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "container py-5 flex-grow-1" },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ handleSubmit }) {
                  return [
                    _c(
                      "form",
                      {
                        staticClass: "checkout-form",
                        attrs: { action: "/payment", method: "post" },
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.submit)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c("h1", [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.__("webshop.Bestellen")) +
                                  "\n                "
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "row mt-4 justify-content-between" },
                          [
                            _c(
                              "div",
                              { staticClass: "col-lg-7" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "form-group inputs" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "row",
                                        on: {
                                          change: function ($event) {
                                            return _vm.findDealers()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "col-6" },
                                          [
                                            _c("validation-provider", {
                                              attrs: { rules: "required" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ errors }) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "input-container",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                attrs: {
                                                                  for: "first_name",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.__(
                                                                      "webshop.Voornaam"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.checkout
                                                                      .first_name,
                                                                  expression:
                                                                    "checkout.first_name",
                                                                },
                                                              ],
                                                              attrs: {
                                                                id: "first_name",
                                                                type: "text",
                                                                name: "first_name",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.checkout
                                                                    .first_name,
                                                              },
                                                              on: {
                                                                focus:
                                                                  _vm.inputFocus,
                                                                blur: _vm.inputBlur,
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.$set(
                                                                      _vm.checkout,
                                                                      "first_name",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "invalid-feedback d-block",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(errors[0])
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-6" },
                                          [
                                            _c("validation-provider", {
                                              attrs: { rules: "required" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ errors }) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "input-container",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                attrs: {
                                                                  for: "name",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.__(
                                                                      "webshop.Naam"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.checkout
                                                                      .name,
                                                                  expression:
                                                                    "checkout.name",
                                                                },
                                                              ],
                                                              attrs: {
                                                                id: "name",
                                                                type: "text",
                                                                name: "name",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.checkout
                                                                    .name,
                                                              },
                                                              on: {
                                                                focus:
                                                                  _vm.inputFocus,
                                                                blur: _vm.inputBlur,
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.$set(
                                                                      _vm.checkout,
                                                                      "name",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "invalid-feedback d-block",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(errors[0])
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-8 mt-4" },
                                          [
                                            _c("validation-provider", {
                                              attrs: { rules: "required" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ errors }) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "input-container",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                attrs: {
                                                                  for: "address_street",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.__(
                                                                      "webshop.Straat"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.checkout
                                                                      .address_street,
                                                                  expression:
                                                                    "checkout.address_street",
                                                                },
                                                              ],
                                                              attrs: {
                                                                id: "address_street",
                                                                type: "text",
                                                                name: "address_street",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.checkout
                                                                    .address_street,
                                                              },
                                                              on: {
                                                                focus:
                                                                  _vm.inputFocus,
                                                                blur: _vm.inputBlur,
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.$set(
                                                                      _vm.checkout,
                                                                      "address_street",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "invalid-feedback d-block",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(errors[0])
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-2 mt-4" },
                                          [
                                            _c("validation-provider", {
                                              attrs: { rules: "required" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ errors }) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "input-container",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                attrs: {
                                                                  for: "address_housenumber",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.__(
                                                                      "webshop.Nr"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.checkout
                                                                      .address_housenumber,
                                                                  expression:
                                                                    "checkout.address_housenumber",
                                                                },
                                                              ],
                                                              attrs: {
                                                                id: "address_housenumber",
                                                                type: "text",
                                                                name: "address_housenumber",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.checkout
                                                                    .address_housenumber,
                                                              },
                                                              on: {
                                                                focus:
                                                                  _vm.inputFocus,
                                                                blur: _vm.inputBlur,
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.$set(
                                                                      _vm.checkout,
                                                                      "address_housenumber",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "invalid-feedback d-block",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(errors[0])
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-2 mt-4" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "input-container",
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    attrs: {
                                                      for: "address_addition",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.__("webshop.Toev")
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.checkout
                                                          .address_addition,
                                                      expression:
                                                        "checkout.address_addition",
                                                    },
                                                  ],
                                                  attrs: {
                                                    id: "address_addition",
                                                    type: "text",
                                                    name: "address_addition",
                                                  },
                                                  domProps: {
                                                    value:
                                                      _vm.checkout
                                                        .address_addition,
                                                  },
                                                  on: {
                                                    focus: _vm.inputFocus,
                                                    blur: _vm.inputBlur,
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.checkout,
                                                        "address_addition",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-4 mt-4" },
                                          [
                                            _c("validation-provider", {
                                              attrs: { rules: "required" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ errors }) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "input-container",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                attrs: {
                                                                  for: "address_zip",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.__(
                                                                      "webshop.Postcode"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.checkout
                                                                      .address_zip,
                                                                  expression:
                                                                    "checkout.address_zip",
                                                                },
                                                              ],
                                                              attrs: {
                                                                id: "address_zip",
                                                                type: "text",
                                                                name: "address_zip",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.checkout
                                                                    .address_zip,
                                                              },
                                                              on: {
                                                                focus:
                                                                  _vm.inputFocus,
                                                                blur: _vm.inputBlur,
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.$set(
                                                                      _vm.checkout,
                                                                      "address_zip",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "invalid-feedback d-block",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(errors[0])
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-lg-4 mt-4" },
                                          [
                                            _c("validation-provider", {
                                              attrs: { rules: "required" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ errors }) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "input-container",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                attrs: {
                                                                  for: "address_city",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.__(
                                                                      "webshop.Plaats"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.checkout
                                                                      .address_city,
                                                                  expression:
                                                                    "checkout.address_city",
                                                                },
                                                              ],
                                                              attrs: {
                                                                id: "address_city",
                                                                type: "text",
                                                                name: "address_city",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.checkout
                                                                    .address_city,
                                                              },
                                                              on: {
                                                                focus:
                                                                  _vm.inputFocus,
                                                                blur: _vm.inputBlur,
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.$set(
                                                                      _vm.checkout,
                                                                      "address_city",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "invalid-feedback d-block",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(errors[0])
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-lg-4 mt-4" },
                                          [
                                            _c("validation-provider", {
                                              attrs: { rules: "required" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ errors }) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "input-container",
                                                          },
                                                          [
                                                            _c(
                                                              "select",
                                                              {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      _vm
                                                                        .checkout
                                                                        .address_country,
                                                                    expression:
                                                                      "checkout.address_country",
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  name: "address_country",
                                                                  id: "",
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      var $$selectedVal =
                                                                        Array.prototype.filter
                                                                          .call(
                                                                            $event
                                                                              .target
                                                                              .options,
                                                                            function (
                                                                              o
                                                                            ) {
                                                                              return o.selected
                                                                            }
                                                                          )
                                                                          .map(
                                                                            function (
                                                                              o
                                                                            ) {
                                                                              var val =
                                                                                "_value" in
                                                                                o
                                                                                  ? o._value
                                                                                  : o.value
                                                                              return val
                                                                            }
                                                                          )
                                                                      _vm.$set(
                                                                        _vm.checkout,
                                                                        "address_country",
                                                                        $event
                                                                          .target
                                                                          .multiple
                                                                          ? $$selectedVal
                                                                          : $$selectedVal[0]
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value: "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.__(
                                                                          "webshop.Land"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "België",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.__(
                                                                          "webshop.België"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "option",
                                                                  {
                                                                    attrs: {
                                                                      value:
                                                                        "Nederland",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.__(
                                                                          "webshop.Nederland"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "invalid-feedback d-block",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(errors[0])
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-6 mt-4" },
                                          [
                                            _c("validation-provider", {
                                              attrs: { rules: "required" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ errors }) {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "input-container",
                                                          },
                                                          [
                                                            _c(
                                                              "label",
                                                              {
                                                                attrs: {
                                                                  for: "email",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.__(
                                                                      "webshop.E-mailadres"
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model",
                                                                  value:
                                                                    _vm.checkout
                                                                      .email,
                                                                  expression:
                                                                    "checkout.email",
                                                                },
                                                              ],
                                                              attrs: {
                                                                id: "email",
                                                                type: "email",
                                                                name: "email",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm.checkout
                                                                    .email,
                                                              },
                                                              on: {
                                                                focus:
                                                                  _vm.inputFocus,
                                                                blur: _vm.inputBlur,
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.$set(
                                                                      _vm.checkout,
                                                                      "email",
                                                                      $event
                                                                        .target
                                                                        .value
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "invalid-feedback d-block",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(errors[0])
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-6 mt-4" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "input-container",
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  { attrs: { for: "phone" } },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.__(
                                                          "webshop.Telefoon"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.checkout.phone,
                                                      expression:
                                                        "checkout.phone",
                                                    },
                                                  ],
                                                  attrs: {
                                                    id: "phone",
                                                    type: "phone",
                                                    name: "phone",
                                                  },
                                                  domProps: {
                                                    value: _vm.checkout.phone,
                                                  },
                                                  on: {
                                                    focus: _vm.inputFocus,
                                                    blur: _vm.inputBlur,
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        _vm.checkout,
                                                        "phone",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "transition",
                                  { attrs: { name: "fade" } },
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        rules: "required",
                                        customMessages: {
                                          required:
                                            "Gelieve een verdeler te selecteren.",
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _vm.sortedDealers.length > 0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass: "row mt-4",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-12 mb-3",
                                                          },
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.__(
                                                                    "webshop.Selecteer een van onze verdelers in je buurt"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm._l(
                                                          _vm.sortedDealers,
                                                          function (
                                                            dealer,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "col-lg-6",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "border",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "row align-items-center",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "col-1",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "input",
                                                                              {
                                                                                directives:
                                                                                  [
                                                                                    {
                                                                                      name: "model",
                                                                                      rawName:
                                                                                        "v-model",
                                                                                      value:
                                                                                        _vm
                                                                                          .checkout
                                                                                          .dealerID,
                                                                                      expression:
                                                                                        "checkout.dealerID",
                                                                                    },
                                                                                  ],
                                                                                attrs:
                                                                                  {
                                                                                    type: "radio",
                                                                                    name: "dealer",
                                                                                  },
                                                                                domProps:
                                                                                  {
                                                                                    value:
                                                                                      dealer.id,
                                                                                    checked:
                                                                                      _vm._q(
                                                                                        _vm
                                                                                          .checkout
                                                                                          .dealerID,
                                                                                        dealer.id
                                                                                      ),
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.$set(
                                                                                        _vm.checkout,
                                                                                        "dealerID",
                                                                                        dealer.id
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "col-10",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "strong",
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    dealer.company
                                                                                  )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _c(
                                                                              "br"
                                                                            ),
                                                                            _vm._v(
                                                                              "\n                                                " +
                                                                                _vm._s(
                                                                                  dealer.address
                                                                                ) +
                                                                                " " +
                                                                                _vm._s(
                                                                                  dealer.housenumber
                                                                                ) +
                                                                                " " +
                                                                                _vm._s(
                                                                                  dealer.addition
                                                                                )
                                                                            ),
                                                                            _c(
                                                                              "br"
                                                                            ),
                                                                            _vm._v(
                                                                              "\n                                                " +
                                                                                _vm._s(
                                                                                  dealer.zip
                                                                                ) +
                                                                                " " +
                                                                                _vm._s(
                                                                                  dealer.city
                                                                                ) +
                                                                                "\n                                            "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "col-12 invalid-feedback d-block",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(errors[0])
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      2
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "transition",
                                  { attrs: { name: "fade" } },
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        rules: "required",
                                        customMessages: {
                                          required:
                                            "Gelieve een levermethode te selecteren.",
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function ({ errors }) {
                                              return [
                                                _vm.sortedDealers.length > 0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass: "row mt-4",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "col-12 mb-3",
                                                          },
                                                          [
                                                            _c("strong", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.__(
                                                                    "webshop.Kies een levermethode"
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "col-6",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "border",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "row align-items-center",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "col-1",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {},
                                                                          [
                                                                            _c(
                                                                              "input",
                                                                              {
                                                                                directives:
                                                                                  [
                                                                                    {
                                                                                      name: "model",
                                                                                      rawName:
                                                                                        "v-model",
                                                                                      value:
                                                                                        _vm
                                                                                          .checkout
                                                                                          .deliveryMethod,
                                                                                      expression:
                                                                                        "checkout.deliveryMethod",
                                                                                    },
                                                                                  ],
                                                                                attrs:
                                                                                  {
                                                                                    type: "radio",
                                                                                    name: "delivery",
                                                                                    id: "ship",
                                                                                    value:
                                                                                      "ship",
                                                                                  },
                                                                                domProps:
                                                                                  {
                                                                                    checked:
                                                                                      _vm._q(
                                                                                        _vm
                                                                                          .checkout
                                                                                          .deliveryMethod,
                                                                                        "ship"
                                                                                      ),
                                                                                  },
                                                                                on: {
                                                                                  change:
                                                                                    function (
                                                                                      $event
                                                                                    ) {
                                                                                      return _vm.$set(
                                                                                        _vm.checkout,
                                                                                        "deliveryMethod",
                                                                                        "ship"
                                                                                      )
                                                                                    },
                                                                                },
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "col-10",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "row align-items-center",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "col-auto",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "i",
                                                                                  {
                                                                                    staticClass:
                                                                                      "fal fa-2x fa-shipping-fast",
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "col",
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                    " +
                                                                                    _vm._s(
                                                                                      _vm.__(
                                                                                        "webshop.Leveren"
                                                                                      )
                                                                                    ) +
                                                                                    "\n                                                "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "col-12 invalid-feedback d-block",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(errors[0])
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-lg-5" }, [
                              _c("div", { staticClass: "border" }, [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-12 totals" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "row justify-content-between",
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass: "btn",
                                            attrs: {
                                              type: "button",
                                              "data-bs-target": "#exampleModal",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                _vm.showProducts = true
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.__(
                                                    "webshop.Bekijk producten"
                                                  )
                                                ) +
                                                "\n                                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "transition",
                                          {
                                            attrs: {
                                              name: "fade",
                                              duration: 250,
                                            },
                                          },
                                          [
                                            _vm.showProducts
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "modal fade show",
                                                    staticStyle: {
                                                      display: "block",
                                                      background:
                                                        "rgba(0,0,0, .6)",
                                                    },
                                                    attrs: {
                                                      id: "exampleModal",
                                                      tabindex: "-1",
                                                      "aria-labelledby":
                                                        "exampleModalLabel",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "modal-dialog modal-dialog-centered",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "modal-content",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "modal-body p-4",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "block mb-3 py-2",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "row",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "col-7",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.__(
                                                                                  "webshop.Product"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "col-3 text-center",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.__(
                                                                                  "webshop.Aantal"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "col-2 text-right",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm.__(
                                                                                  "webshop.Totaal"
                                                                                )
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _vm._l(
                                                                  _vm.cart,
                                                                  function (
                                                                    item,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "block mb-3",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "row align-items-center cart-item",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "col-2",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "img",
                                                                                  {
                                                                                    staticClass:
                                                                                      "w-100",
                                                                                    attrs:
                                                                                      {
                                                                                        src: item
                                                                                          .product
                                                                                          .featured_image,
                                                                                        alt: "",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        type: "hidden",
                                                                                        name:
                                                                                          "products[" +
                                                                                          index +
                                                                                          "][id]",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value:
                                                                                          item
                                                                                            .product
                                                                                            .id,
                                                                                      },
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        type: "hidden",
                                                                                        name:
                                                                                          "products[" +
                                                                                          index +
                                                                                          "][qty]",
                                                                                      },
                                                                                    domProps:
                                                                                      {
                                                                                        value:
                                                                                          item.qty,
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "col-5",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "strong",
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        item
                                                                                          .product
                                                                                          .brand
                                                                                      )
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "br"
                                                                                ),
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      item
                                                                                        .product
                                                                                        .name
                                                                                    )
                                                                                ),
                                                                                _c(
                                                                                  "br"
                                                                                ),
                                                                                _vm._v(
                                                                                  " € " +
                                                                                    _vm._s(
                                                                                      _vm.formatPrice(
                                                                                        item
                                                                                          .product
                                                                                          .private_customer_price
                                                                                      )
                                                                                    ) +
                                                                                    "\n                                                        "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "col-3 text-center",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "input-container filled",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                                " +
                                                                                        _vm._s(
                                                                                          item.qty
                                                                                        ) +
                                                                                        "\n                                                            "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "col-2 text-right",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "item-price",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                                € " +
                                                                                        _vm._s(
                                                                                          _vm.formatPrice(
                                                                                            item
                                                                                              .product
                                                                                              .private_customer_price *
                                                                                              item.qty
                                                                                          )
                                                                                        ) +
                                                                                        "\n                                                            "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                              ],
                                                              2
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "modal-footer",
                                                              },
                                                              [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn",
                                                                    attrs: {
                                                                      type: "button",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          $event.preventDefault()
                                                                          _vm.showProducts = false
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.__(
                                                                          "webshop.Sluiten"
                                                                        )
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-12 font-weight-bold text-uppercase py-4",
                                          },
                                          [
                                            _c(
                                              "h5",
                                              {
                                                staticClass: "font-weight-bold",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.__("webshop.Totalen")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-12 pb-2" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "row justify-content-between",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-auto py-2",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(
                                                          _vm.__(
                                                            "webshop.Subtotaal"
                                                          )
                                                        ) +
                                                        "\n                                        "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "col-auto py-2 cart-subtotal",
                                                    attrs: {
                                                      id: "cart-subtotal",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                            € " +
                                                        _vm._s(
                                                          _vm.formatPrice(
                                                            _vm.cartTotals
                                                              .subtotal
                                                          )
                                                        ) +
                                                        "\n                                        "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.cartTotals.shipping
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "row justify-content-between",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-auto py-2",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                            " +
                                                            _vm._s(
                                                              _vm.__(
                                                                "webshop.Verzending"
                                                              )
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-auto py-2",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                            € " +
                                                            _vm._s(
                                                              _vm.formatPrice(
                                                                _vm.cartTotals
                                                                  .shipping
                                                              )
                                                            ) +
                                                            "\n                                        "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    { staticClass: "col-12 totals border-top" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "row justify-content-between",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-auto font-weight-bold py-4",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(
                                                    _vm.__(
                                                      "webshop.TotaalprijsinclBTW"
                                                    )
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-auto font-weight-bold py-4 cart-total",
                                              attrs: { id: "cart-total" },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                    € " +
                                                  _vm._s(
                                                    _vm.formatPrice(
                                                      _vm.cartTotals.total
                                                    )
                                                  ) +
                                                  "\n                                "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "row" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-12 payment-methods border-top",
                                    },
                                    [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-12 font-weight-bold pt-4",
                                          },
                                          [
                                            _vm._v(
                                              "\n                            " +
                                                _vm._s(
                                                  _vm.__(
                                                    "webshop.Betaalmethode"
                                                  )
                                                ) +
                                                "\n                        "
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "col-12" },
                                          [
                                            _c("validation-provider", {
                                              attrs: { rules: "required" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function ({ errors }) {
                                                      return [
                                                        _vm._l(
                                                          _vm.paymentMethods,
                                                          function (method) {
                                                            return _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "d-block",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "row py-3 align-items-center",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "col-1",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "input",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    _vm
                                                                                      .checkout
                                                                                      .paymentMethod,
                                                                                  expression:
                                                                                    "checkout.paymentMethod",
                                                                                },
                                                                              ],
                                                                            attrs:
                                                                              {
                                                                                type: "radio",
                                                                                id: method.id,
                                                                                name: "payment_method",
                                                                              },
                                                                            domProps:
                                                                              {
                                                                                value:
                                                                                  method.id,
                                                                                checked:
                                                                                  _vm._q(
                                                                                    _vm
                                                                                      .checkout
                                                                                      .paymentMethod,
                                                                                    method.id
                                                                                  ),
                                                                              },
                                                                            on: {
                                                                              change:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.$set(
                                                                                    _vm.checkout,
                                                                                    "paymentMethod",
                                                                                    method.id
                                                                                  )
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "col-auto",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "img",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                width:
                                                                                  "50px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                src: method
                                                                                  .image
                                                                                  .size2x,
                                                                                alt: "",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm._v(" "),
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "col 9",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "strong",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                method.description
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "invalid-feedback d-block",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "d-block border-top",
                                              },
                                              [
                                                _c("validation-provider", {
                                                  attrs: { rules: "accepted" },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function ({
                                                          errors,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "row py-3 align-items-center",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-1",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "input",
                                                                      {
                                                                        directives:
                                                                          [
                                                                            {
                                                                              name: "model",
                                                                              rawName:
                                                                                "v-model",
                                                                              value:
                                                                                _vm
                                                                                  .checkout
                                                                                  .agree,
                                                                              expression:
                                                                                "checkout.agree",
                                                                            },
                                                                          ],
                                                                        attrs: {
                                                                          type: "checkbox",
                                                                          id: "check",
                                                                          name: "check",
                                                                          value:
                                                                            "accepted",
                                                                        },
                                                                        domProps:
                                                                          {
                                                                            checked:
                                                                              Array.isArray(
                                                                                _vm
                                                                                  .checkout
                                                                                  .agree
                                                                              )
                                                                                ? _vm._i(
                                                                                    _vm
                                                                                      .checkout
                                                                                      .agree,
                                                                                    "accepted"
                                                                                  ) >
                                                                                  -1
                                                                                : _vm
                                                                                    .checkout
                                                                                    .agree,
                                                                          },
                                                                        on: {
                                                                          change:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              var $$a =
                                                                                  _vm
                                                                                    .checkout
                                                                                    .agree,
                                                                                $$el =
                                                                                  $event.target,
                                                                                $$c =
                                                                                  $$el.checked
                                                                                    ? true
                                                                                    : false
                                                                              if (
                                                                                Array.isArray(
                                                                                  $$a
                                                                                )
                                                                              ) {
                                                                                var $$v =
                                                                                    "accepted",
                                                                                  $$i =
                                                                                    _vm._i(
                                                                                      $$a,
                                                                                      $$v
                                                                                    )
                                                                                if (
                                                                                  $$el.checked
                                                                                ) {
                                                                                  $$i <
                                                                                    0 &&
                                                                                    _vm.$set(
                                                                                      _vm.checkout,
                                                                                      "agree",
                                                                                      $$a.concat(
                                                                                        [
                                                                                          $$v,
                                                                                        ]
                                                                                      )
                                                                                    )
                                                                                } else {
                                                                                  $$i >
                                                                                    -1 &&
                                                                                    _vm.$set(
                                                                                      _vm.checkout,
                                                                                      "agree",
                                                                                      $$a
                                                                                        .slice(
                                                                                          0,
                                                                                          $$i
                                                                                        )
                                                                                        .concat(
                                                                                          $$a.slice(
                                                                                            $$i +
                                                                                              1
                                                                                          )
                                                                                        )
                                                                                    )
                                                                                }
                                                                              } else {
                                                                                _vm.$set(
                                                                                  _vm.checkout,
                                                                                  "agree",
                                                                                  $$c
                                                                                )
                                                                              }
                                                                            },
                                                                        },
                                                                      }
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col 9",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.__(
                                                                          "webshop.Ik aanvaard de algemene voorwaarden"
                                                                        )
                                                                      ) +
                                                                        "\n\n                                        "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "invalid-feedback d-block",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "row no-gutters" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "site-btn w-100 text-center",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.__(
                                              "webshop.Bestelling afronden"
                                            )
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }