var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex flex-column justify-content-between",
      staticStyle: { "min-height": "100vh" },
    },
    [
      _c("header", [
        _c("div", { staticClass: "container px-2" }, [
          _c("div", { staticClass: "navbar px-0" }, [
            _c(
              "div",
              {
                staticClass:
                  "row align-items-center justify-content-between w-100",
              },
              [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "col-5 col-lg-8 pr-0" }, [
                  _c("div", { staticClass: "tm-header-mobile d-lg-none" }, [
                    _c("div", { attrs: { "show-on-up": "" } }, [
                      _c("div", { staticClass: "uk-navbar-container" }, [
                        _c(
                          "nav",
                          {
                            staticClass: "uk-navbar",
                            attrs: { "uk-navbar": "" },
                          },
                          [
                            _c("div", { staticClass: "uk-navbar-right" }, [
                              _c(
                                "a",
                                {
                                  staticClass: "uk-navbar-toggle",
                                  attrs: {
                                    href: "#tm-mobile",
                                    "uk-toggle": "animation: true",
                                    "aria-expanded": "false",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "uk-icon uk-navbar-toggle-icon",
                                      attrs: { "uk-navbar-toggle-icon": "" },
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          attrs: {
                                            width: "20",
                                            height: "20",
                                            viewBox: "0 0 20 20",
                                            xmlns: "http://www.w3.org/2000/svg",
                                          },
                                        },
                                        [
                                          _c("rect", {
                                            attrs: {
                                              y: "9",
                                              width: "20",
                                              height: "2",
                                            },
                                          }),
                                          _c("rect", {
                                            attrs: {
                                              y: "3",
                                              width: "20",
                                              height: "2",
                                            },
                                          }),
                                          _c("rect", {
                                            attrs: {
                                              y: "15",
                                              width: "20",
                                              height: "2",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "ul",
                                {
                                  staticClass:
                                    "navbar-nav flex-row justify-content-end text-center mt-0",
                                },
                                [
                                  _c(
                                    "li",
                                    { staticClass: "nav-item col-auto pr-0" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass:
                                            "nav-link mini-cart-link",
                                          attrs: { to: { name: "cart" } },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fal fa-shopping-cart",
                                            staticStyle: {
                                              "font-size": "1.5rem",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _vm.$store.getters.count > 0
                                            ? _c(
                                                "small",
                                                {
                                                  staticClass:
                                                    "badge badge-pill badge-dark",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$store.getters.count
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm._m(1),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                    _c("div", {
                      staticClass: "uk-sticky-placeholder",
                      staticStyle: { height: "100px", margin: "0px" },
                      attrs: { hidden: "" },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    {
                      staticClass:
                        "navbar-nav flex-row justify-content-end text-center row d-none d-lg-block",
                    },
                    [
                      _vm._l(_vm.websiteMenuItems, function (menuItem, index) {
                        return menuItem.title !== "Webshop"
                          ? _c("li", { staticClass: "nav-item col-auto" }, [
                              _c("a", {
                                staticClass: "nav-link d-inline-block w-auto",
                                attrs: {
                                  href: menuItem.url,
                                  target: menuItem.target,
                                },
                                domProps: { innerHTML: _vm._s(menuItem.title) },
                              }),
                            ])
                          : _vm._e()
                      }),
                      _vm._v(" "),
                      _c("li", { staticClass: "nav-item col-auto" }, [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link d-inline-block w-auto",
                            attrs: {
                              href: _vm.$router.resolve({
                                name: "home-loc",
                                params: { locale: _vm.$route.params.locale },
                              }).href,
                            },
                          },
                          [
                            _vm._v(
                              "\n                                    Webshop\n                                "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "li",
                        { staticClass: "nav-item col-auto pr-0" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link mini-cart-link",
                              attrs: { to: { name: "cart" } },
                            },
                            [
                              _c("i", {
                                staticClass: "fal fa-shopping-cart",
                                staticStyle: { "font-size": "1.5rem" },
                              }),
                              _vm._v(" "),
                              _vm.$store.getters.count > 0
                                ? _c(
                                    "small",
                                    {
                                      staticClass:
                                        "badge badge-pill badge-dark",
                                    },
                                    [_vm._v(_vm._s(_vm.$store.getters.count))]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.$route.params.locale !== "nl"
                        ? _c("li", { staticClass: "nav-item col-auto pr-0" }, [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link mini-cart-link",
                                attrs: {
                                  href: _vm.$router.resolve({
                                    params: { locale: "nl" },
                                  }).href,
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "wpml-ls-flag",
                                  attrs: {
                                    src: "https://www.kanters.be/wp-content/plugins/sitepress-multilingual-cms/res/flags/nl.png",
                                    alt: "Nederlands",
                                  },
                                }),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.$route.params.locale !== "fr"
                        ? _c("li", { staticClass: "nav-item col-auto pr-0" }, [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link mini-cart-link",
                                attrs: {
                                  href: _vm.$router.resolve({
                                    params: { locale: "fr" },
                                  }).href,
                                },
                              },
                              [
                                _c("img", {
                                  staticClass: "wpml-ls-flag",
                                  attrs: {
                                    src: "https://www.kanters.be/wp-content/plugins/sitepress-multilingual-cms/res/flags/fr.png",
                                    alt: "Nederlands",
                                  },
                                }),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "tm-mobile", hidden: "" } }, [
        _c(
          "div",
          { staticClass: "uk-background-default uk-padding uk-text-center" },
          [
            _c(
              "div",
              {
                staticClass: "uk-child-width-1-1 uk-grid uk-grid-stack",
                attrs: { "uk-grid": "" },
              },
              [
                _c("div", [
                  _c("div", { staticClass: "uk-panel" }, [
                    _c(
                      "ul",
                      { staticClass: "uk-nav uk-nav-default uk-nav-center" },
                      [
                        _vm._l(
                          _vm.websiteMenuItems,
                          function (menuItem, index) {
                            return menuItem.title !== "Webshop"
                              ? _c("li", { staticClass: "nav-item col-auto" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "nav-link d-inline-block w-auto",
                                      attrs: { href: menuItem.url },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(menuItem.title) +
                                          "\n                                "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e()
                          }
                        ),
                        _vm._v(" "),
                        _c(
                          "li",
                          { staticClass: "nav-item col-auto" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "nav-link d-inline-block w-auto",
                                attrs: { to: { name: "home" } },
                              },
                              [
                                _vm._v(
                                  "\n                                    Webshop\n                                "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm._m(2),
                      ],
                      2
                    ),
                  ]),
                ]),
              ]
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: { name: "slide", mode: "out-in" },
          on: {
            beforeLeave: _vm.fadeBeforeLeave,
            enter: _vm.fadeEnter,
            afterEnter: _vm.fadeAfterEnter,
          },
        },
        [_c("router-view")],
        1
      ),
      _vm._v(" "),
      _c("footer", { staticClass: "footer" }, [
        _c("div", { staticClass: "uk-container" }, [
          _c(
            "div",
            {
              staticClass: "tm-grid-expand uk-grid-margin uk-grid",
              attrs: { "uk-grid": "" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "uk-grid-item-match uk-flex-middle uk-width-1-2@m uk-first-column",
                },
                [
                  _c("div", { staticClass: "uk-panel uk-width-1-1" }, [
                    _c("div", { staticClass: "uk-panel uk-margin" }, [
                      _c("h2", [
                        _vm._v(_vm._s(_vm.__("webshop.Onze openingsuren"))),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          _vm._s(_vm.__("webshop.Ma - Vrij: 09u00 - 17u30"))
                        ),
                        _c("br"),
                        _vm._v(
                          "\n                                    " +
                            _vm._s(
                              _vm.__("webshop.Zaterdag en zondag gesloten")
                            )
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "uk-grid-item-match uk-flex-middle uk-width-1-2@m",
                },
                [
                  _c("div", { staticClass: "uk-panel uk-width-1-1" }, [
                    _vm._m(3),
                    _c("div", { staticClass: "uk-panel uk-margin" }, [
                      _c("ul", { staticClass: "footer-right" }, [
                        _c("li", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://kanters.be/algemene-voorwaarden/",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.__("webshop.Algemene voorwaarden"))
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://kanters.be/levertijden-en-verzendkosten/",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.__("webshop.Levertijden en verzendkosten")
                                )
                              ),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://kanters.be/retourneren/",
                              },
                            },
                            [_vm._v(_vm._s(_vm.__("webshop.Retourneren")))]
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "uk-panel uk-margin" }, [
                      _c(
                        "ul",
                        {
                          staticClass: "footer-right",
                          staticStyle: { top: "-20px" },
                        },
                        [
                          _c("li", [_vm._v("© 2021")]),
                          _vm._v(" "),
                          _c("li", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://kanters.be//disclaimer/",
                                },
                              },
                              [_vm._v(_vm._s(_vm.__("webshop.Disclaimer")))]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("li", [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "https://kanters.be//privacy-policy/",
                                },
                              },
                              [_vm._v(_vm._s(_vm.__("webshop.Privacy Policy")))]
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._m(4),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "col-7 text-center text-lg-left col-lg-4 pr-0" },
      [
        _c(
          "a",
          {
            staticClass: "navbar-brand",
            attrs: { href: "https://www.kanters.be" },
          },
          [
            _c("img", {
              attrs: {
                src: "/img/Logo-Kanters_bruin.png",
                alt: "Kanters Haar- en Nagelstyling",
              },
            }),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "li",
      { staticClass: "nav-item col-auto pr-0 d-none d-lg-block" },
      [
        _c("img", {
          staticClass: "wpml-ls-flag",
          attrs: {
            src: "https://www.kanters.be/wp-content/plugins/sitepress-multilingual-cms/res/flags/fr.png",
            alt: "Frans",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "nav-item col-auto d-lg-none" }, [
      _c("img", {
        staticClass: "wpml-ls-flag",
        attrs: {
          src: "https://www.kanters.be/wp-content/plugins/sitepress-multilingual-cms/res/flags/fr.png",
          alt: "Frans",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "uk-panel uk-margin" }, [
      _c("ul", { staticClass: "footer-left" }, [
        _c("li", [_vm._v("Fabriekstraat 104C | 3900 Pelt")]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "tel:+3211641289" } }, [
            _vm._v("011/64 12 89"),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [
          _c("a", { attrs: { href: "mailto:info@kanters.be" } }, [
            _vm._v("info@kanters.be"),
          ]),
        ]),
        _vm._v(" "),
        _c("li", [_vm._v("BE0473 231 821")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [
      _c(
        "a",
        {
          attrs: {
            href: "https://www.creatic.com",
            target: "_blank",
            rel: "noopener",
          },
        },
        [_vm._v("Creatic.Agency")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }