var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "search-bar-webshop mb-5", attrs: { id: "search-bar" } },
    [
      _c("div", { staticClass: "container search-bar-top" }, [
        _c("div", { staticClass: "row py-5 align-items-center" }, [
          _c("div", { staticClass: "col-12 col-lg-5" }, [
            _c("div", { staticClass: "input-group search-group" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.keyword,
                    expression: "keyword",
                  },
                ],
                staticClass: "search form-control",
                attrs: {
                  type: "text",
                  placeholder: _vm.__("webshop.zoek-placeholder"),
                },
                domProps: { value: _vm.keyword },
                on: {
                  keyup: function ($event) {
                    $event.stopPropagation()
                    return _vm.runSearch.apply(null, arguments)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.keyword = $event.target.value
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-12 mt-3 mt-lg-0 col-lg-7" }, [
            _c(
              "div",
              {
                staticClass: "row justify-content-end",
                staticStyle: { "font-size": "1.2rem" },
              },
              [
                _c("div", { staticClass: "col-lg-auto" }, [
                  _c("img", {
                    staticClass: "mr-3",
                    staticStyle: { height: "16px", width: "20px" },
                    attrs: { src: "/img/truck_svg.svg", alt: "" },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        color: "#ffffff",
                        display: "inline-block",
                        "padding-top": "5px",
                        "font-size": "1rem",
                      },
                    },
                    [_vm._v(_vm._s(_vm.__("webshop.Snelle verzending")))]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-auto" }, [
                  _c("img", {
                    staticClass: "mr-3",
                    staticStyle: { height: "16px", width: "20px" },
                    attrs: { src: "/img/leaf_svg.svg", alt: "" },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        color: "#ffffff",
                        display: "inline-block",
                        "padding-top": "5px",
                        "font-size": "1rem",
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.__("webshop.Met natuurlijke producten"))
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-lg-auto" }, [
                  _c("img", {
                    staticClass: "mr-3",
                    staticStyle: { height: "18px", width: "20px" },
                    attrs: { src: "/img/medal_svg.svg", alt: "" },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        color: "#ffffff",
                        display: "inline-block",
                        "padding-top": "5px",
                        "font-size": "1rem",
                      },
                    },
                    [_vm._v(_vm._s(_vm.__("webshop.Top service")))]
                  ),
                ]),
              ]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm._m(1),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("i", { staticClass: "fal fa-search pt-1" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "container search-bar-bottom" }, [
      _c("div", { staticClass: "row py-3 align-items-center" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "p",
            {
              staticStyle: {
                "margin-top": "0px",
                "margin-bottom": "0px",
                color: "#695446",
                "font-size": "17px",
                "text-align": "center",
              },
            },
            [
              _vm._v("Ben jij een professional in haar of beauty? "),
              _c(
                "a",
                {
                  staticStyle: {
                    color: "#695446",
                    "font-weight": "bold",
                    "text-decoration": "underline",
                    "text-underline-position": "under",
                  },
                  attrs: {
                    href: "https://store.kanters.be/bestelplatform/login",
                  },
                },
                [_vm._v("Klik hier")]
              ),
              _vm._v(" voor uw webshop "),
              _c("strong", [_vm._v("voor professionals")]),
              _vm._v("!"),
            ]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }