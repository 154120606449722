<template>
    <div>
        <div class="row">
            <div class="col-12 mt-4 mb-3">
                <strong class="text-uppercase filter-title">{{ __('webshop.Merken') }}</strong>
            </div>
            <div class="col-12">
                <ul>

                    <li v-for="(category, index) in categories"  v-bind:key="category.id" v-bind:data-index="index">
                        <router-link :to="{name: 'main-brand-category', params: {  slug: category.slug }, query: {page: 1, search: ''}}" >{{ category.name }}</router-link>
                        <ul v-if="category.children.length">
                            <li v-for="child in category.children">
                                <router-link :to="{name: 'brand-category', params: { parent: category.slug, slug: child.slug }, query: {page: 1, search: ''}}" exact>{{ child.name }}</router-link>
                            </li>
                        </ul>
                    </li>

                </ul>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    data ()  {

        return {

           categories: []

        }

    },
    async mounted() {

        await this.$http.get( this.api_url("/brand-categories") + '?locale=' + window._locale)

            .then((response)=>{

                this.categories = response.data.categories

            })

            .catch(()=>{

                console.log("Error........")

            })


    },
    components: {

    },
    methods: {



    },
    computed: {


    },
    created: function() {

    }


}
</script>

<style lang="scss" scoped>
 ul {
     list-style-type: none;
     padding-left: 0;
     li {
         display: block;
         a {
             padding: 4px 0;
             color: #000000;
             text-decoration: none;
             text-transform: uppercase;
             &:hover, &.active {
                 color: #849e1e;
             }
             &:not(.active) ~ ul {
                 display: none;
             }
         }
         ul {
             li{
                 padding-left: 10px;
                 a {
                    text-transform:none;

                 }
             }
         }

     }
 }
</style>
