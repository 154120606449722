var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-grow-1" },
    [
      _c("search-bar"),
      _vm._v(" "),
      _c(
        "section",
        [
          _c("transition", { attrs: { name: "fadeDown", tag: "ul" } }, [
            _vm.message.display
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-12" }, [
                    _c(
                      "div",
                      {
                        class: "alert alert-" + _vm.message.type,
                        staticStyle: { "margin-top": "-50px" },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.message.content) +
                            "\n\n                        "
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "ml-3",
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.clearMessage.apply(null, arguments)
                              },
                            },
                          },
                          [
                            _c("span", { attrs: { "aria-hidden": "true" } }, [
                              _vm._v("×"),
                            ]),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.product
        ? _c("div", { staticClass: "container flex-grow-1" }, [
            _c("div", { staticClass: "row justify-content-center" }, [
              _c("div", { staticClass: "col-lg-10" }, [
                _c("div", { staticClass: "block py-3" }, [
                  _c("div", { staticClass: "row justify-content-between" }, [
                    _c(
                      "div",
                      { staticClass: "col-auto" },
                      [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "brand-category",
                                params: {
                                  parent: _vm.product.brand_category.parent
                                    ? _vm.product.brand_category.parent.slug
                                    : null,
                                  slug: _vm.product.brand_category.slug,
                                },
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "far fa-chevron-left mr-3",
                            }),
                            _vm._v(
                              "  " +
                                _vm._s(_vm.__("webshop.Ga terug")) +
                                "\n                            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-auto" },
                      [
                        _c("router-link", { attrs: { to: { name: "home" } } }, [
                          _vm._v(
                            "\n                                Webshop\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "px-2" }, [_vm._v("/")]),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "main-brand-category",
                                params: {
                                  slug: _vm.product.brand_category.parent
                                    ? _vm.product.brand_category.parent.slug
                                    : _vm.product.brand_category.slug,
                                },
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                                " +
                                _vm._s(
                                  _vm.product.brand_category.parent
                                    ? _vm.product.brand_category.parent.slug
                                    : _vm.product.brand_category.name
                                ) +
                                "\n                            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "px-2" }, [_vm._v("/")]),
                        _vm._v(" "),
                        _vm.product.brand_category.parent
                          ? _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "brand-category",
                                    params: {
                                      parent:
                                        _vm.product.brand_category.parent.slug,
                                      slug: _vm.product.brand_category.slug,
                                    },
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                 " +
                                    _vm._s(_vm.product.brand_category.name) +
                                    "\n                            "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row justify-content-between py-5" }, [
              _vm.product
                ? _c("div", { staticClass: "col-12" }, [
                    _c("div", { staticClass: "row justify-content-center" }, [
                      _c("div", { staticClass: "col-12 col-lg-5 order-lg-2" }, [
                        _c(
                          "div",
                          { staticClass: "product-detail-title pb-4" },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-lg-7" }, [
                                _c("div", [_vm._v(_vm._s(_vm.product.brand))]),
                                _vm._v(" "),
                                _c("h2", [_vm._v(_vm._s(_vm.product.name))]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-lg-5 text-right" },
                                [
                                  _c("div", { staticClass: "price" }, [
                                    _vm._v(
                                      "\n                                € " +
                                        _vm._s(
                                          _vm.formatPrice(
                                            _vm.product.private_customer_price
                                          )
                                        ) +
                                        "\n                            "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row justify-content-end mt-3",
                                    },
                                    [
                                      _c("div", { staticClass: "col-6" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "form-group qty-group",
                                          },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "qty" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.__("webshop.Aantal")
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "d-inline-block qty-down",
                                                on: {
                                                  click: function ($event) {
                                                    _vm.qty--
                                                  },
                                                },
                                              },
                                              [_vm._v("-")]
                                            ),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.qty,
                                                  expression: "qty",
                                                },
                                              ],
                                              staticClass:
                                                "form-control qty-field d-inline-block",
                                              staticStyle: {
                                                height: "43px",
                                                "text-align": "center",
                                              },
                                              attrs: {
                                                type: "number",
                                                id: "qty",
                                                min: "1",
                                                step: "1",
                                                value: "1",
                                                name: "qty",
                                              },
                                              domProps: { value: _vm.qty },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.qty = $event.target.value
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "px-3 d-inline-block qty-up",
                                                on: {
                                                  click: function ($event) {
                                                    _vm.qty++
                                                  },
                                                },
                                              },
                                              [_vm._v("+")]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-auto pl-0" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "site-btn px-3",
                                              staticStyle: {
                                                "font-size": "1.2rem",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.addToCart()
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fal fa-shopping-cart",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "row mt-5 mb-4" }, [
                          _c("div", {
                            staticClass: "col-12",
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.nltobr(_vm.product.description)
                              ),
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _vm.product.video
                          ? _c("div", { staticClass: "row my-4" }, [
                              _c("div", { staticClass: "col-12" }, [
                                _c("div", { staticClass: "block py-2" }, [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "video-btn",
                                      attrs: {
                                        type: "button",
                                        "data-toggle": "modal",
                                        "data-src": _vm.product.video,
                                        "data-target": "#myModal",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "open-video",
                                          staticStyle: {
                                            "font-size": "1rem",
                                            cursor: "pointer",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fal fa-camcorder mr-2",
                                            staticStyle: {
                                              "font-size": "1.2rem",
                                            },
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.__(
                                                  "webshop.Bekijk de productvideo"
                                                )
                                              )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "modal fade",
                                      attrs: {
                                        id: "myModal",
                                        tabindex: "-1",
                                        role: "dialog",
                                        "aria-labelledby": "exampleModalLabel",
                                        "aria-hidden": "true",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "modal-dialog modal-dialog-centered",
                                          attrs: { role: "document" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "modal-content" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "modal-body" },
                                                [
                                                  _vm._m(0),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "embed-responsive embed-responsive-16by9",
                                                    },
                                                    [
                                                      _c("iframe", {
                                                        staticClass:
                                                          "embed-responsive-item",
                                                        attrs: {
                                                          src: _vm.product
                                                            .video,
                                                          id: "video",
                                                          allowscriptaccess:
                                                            "always",
                                                          allow: "autoplay",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-lg-5" }, [
                        _c("div", { staticClass: "row pb-4" }, [
                          _c(
                            "div",
                            { staticClass: "col-10" },
                            [
                              _c(
                                "vueper-slides",
                                {
                                  ref: "vueperslides1",
                                  staticClass: "no-shadow",
                                  attrs: {
                                    touchable: false,
                                    "visible-slides": 1,
                                    "slide-ratio": 1 / 1,
                                    "bullets-outside": "",
                                    "3d": "",
                                    arrows: false,
                                  },
                                  on: {
                                    slide: function ($event) {
                                      return _vm.$refs.vueperslides2.goToSlide(
                                        $event.currentSlide.index,
                                        { emit: false }
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("vueper-slide", {
                                    key: "0",
                                    attrs: {
                                      image: _vm.product.featured_image,
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.$refs.vueperslides2.goToSlide(
                                          0
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.product.images,
                                    function (image, i) {
                                      return _c("vueper-slide", {
                                        key: i + 1,
                                        attrs: { image: image.path },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.$refs.vueperslides2.goToSlide(
                                              i + 1
                                            )
                                          },
                                        },
                                      })
                                    }
                                  ),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "vueper-slides",
                                {
                                  ref: "vueperslides2",
                                  staticClass: "no-shadow py-4",
                                  attrs: {
                                    "visible-slides": 4,
                                    touchable: false,
                                    "slide-multiple": "",
                                    gap: 3,
                                    bullets: false,
                                    "slide-ratio": 1 / 4,
                                    "dragging-distance": 200,
                                    breakpoints: {
                                      800: {
                                        visibleSlides: 4,
                                        slideMultiple: 4,
                                      },
                                    },
                                  },
                                  on: {
                                    slide: function ($event) {
                                      return _vm.$refs.vueperslides1.goToSlide(
                                        $event.currentSlide.index,
                                        { emit: false }
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("vueper-slide", {
                                    key: "0",
                                    attrs: {
                                      image: _vm.product.featured_image,
                                    },
                                    nativeOn: {
                                      click: function ($event) {
                                        return _vm.$refs.vueperslides1.goToSlide(
                                          0
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.product.images,
                                    function (image, i) {
                                      return _c("vueper-slide", {
                                        key: i + 1,
                                        attrs: { image: image.path },
                                        nativeOn: {
                                          click: function ($event) {
                                            return _vm.$refs.vueperslides1.goToSlide(
                                              i + 1
                                            )
                                          },
                                        },
                                      })
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _vm.product && _vm.product.related.length > 0
              ? _c(
                  "div",
                  { staticClass: "row justify-content-center pb-5" },
                  [
                    _c("div", { staticClass: "col-12 text-center mb-4" }, [
                      _c("h3", [
                        _vm._v(
                          _vm._s(_vm.__("webshop.Andere klanten kochten ook:"))
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.product.related, function (rel_product, index) {
                      return _c(
                        "div",
                        {
                          key: rel_product.id,
                          staticClass: "col-6 col-lg-3 mb-5 list-complete-item",
                          attrs: { "data-index": index },
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "product-item",
                              attrs: {
                                to: {
                                  name: "product",
                                  params: {
                                    category: rel_product.brand_category.parent
                                      ? rel_product.brand_category.parent.slug
                                      : null,
                                    subcategory:
                                      rel_product.brand_category.slug,
                                    slug: rel_product.slug,
                                  },
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "img-container" }, [
                                _c("img", {
                                  staticClass: "w-100",
                                  attrs: {
                                    src: rel_product.featured_image,
                                    alt: "",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "product-info" }, [
                                _c(
                                  "div",
                                  { staticClass: "row align-items-center" },
                                  [
                                    _c("div", { staticClass: "col-12" }, [
                                      _c("strong", [
                                        _vm._v(_vm._s(rel_product.brand)),
                                      ]),
                                      _c("br"),
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(rel_product.name)
                                      ),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "price" }, [
                                        _vm._v(
                                          "€ " +
                                            _vm._s(
                                              _vm.formatPrice(
                                                rel_product.private_customer_price
                                              )
                                            )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }