module.exports = {
    methods: {
        asset(path) {
            var base_path = window._asset || '';
            return base_path + path;
        },

        api_url(path) {
            var base_path = window._api_url || '';
            return base_path + path;
        }
    }
}
