var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-grow-1" },
    [
      _c("search-bar"),
      _vm._v(" "),
      _c("div", { staticClass: "container pb-5" }, [
        _c("div", { staticClass: "row pt-4 justify-content-between" }, [
          _c("div", { staticClass: "col-lg-3 pr-5" }, [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "block" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "text-uppercase font-weight-bold",
                        attrs: { for: "order" },
                      },
                      [_vm._v(_vm._s(_vm.__("webshop.Sorteren op")))]
                    ),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.order,
                            expression: "order",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { name: "order", id: "order" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.order = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "price-asc" } }, [
                          _vm._v(_vm._s(_vm.__("webshop.Prijs, oplopend"))),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "price-desc" } }, [
                          _vm._v(_vm._s(_vm.__("webshop.Prijs, aflopend"))),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "newest" } }, [
                          _vm._v(_vm._s(_vm.__("webshop.Nieuwste"))),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "oldest" } }, [
                          _vm._v(_vm._s(_vm.__("webshop.Oudste"))),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 mt-4 mb-4" }, [
                _c(
                  "div",
                  { staticClass: "border py-3" },
                  [
                    _c("BrandCategoryFilter"),
                    _vm._v(" "),
                    _c("CategoryFilter"),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-lg-9" },
            [
              _c(
                "transition-group",
                {
                  staticClass: "row",
                  attrs: { name: "list-complete", tag: "div" },
                },
                _vm._l(_vm.category.topproducts, function (product, index) {
                  return _vm.page == 1 && _vm.keyword == ""
                    ? _c(
                        "div",
                        {
                          key: product.id,
                          staticClass: "col-lg-4 mb-5 list-complete-item top",
                          attrs: { "data-index": index },
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "product-item top",
                              attrs: {
                                to: {
                                  name: "product",
                                  params: {
                                    category: _vm.category.parent
                                      ? _vm.category.parent.slug
                                      : null,
                                    subcategory: _vm.category.slug,
                                    slug: product.slug,
                                  },
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "img-container" }, [
                                _c("img", {
                                  staticClass: "w-100",
                                  attrs: {
                                    src: product.featured_image,
                                    alt: "",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "product-info" }, [
                                _c(
                                  "div",
                                  { staticClass: "row align-items-center" },
                                  [
                                    _c("div", { staticClass: "col-12" }, [
                                      _c("strong", [
                                        _vm._v(_vm._s(product.brand)),
                                      ]),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c("strong", [
                                        _vm._v(_vm._s(product.name)),
                                      ]),
                                      _c("br"),
                                      _vm._v(
                                        "\n                                        " +
                                          _vm._s(product.short_description)
                                      ),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "price" }, [
                                        _vm._v(
                                          "€ " +
                                            _vm._s(
                                              _vm.formatPrice(
                                                product.private_customer_price
                                              )
                                            )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e()
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "transition-group",
                {
                  staticClass: "row",
                  attrs: { name: "list-complete", tag: "div" },
                },
                _vm._l(_vm.sorted, function (product, index) {
                  return index < _vm.page * _vm.itemsPerPage &&
                    index > (_vm.page - 1) * _vm.itemsPerPage - 1
                    ? _c(
                        "div",
                        {
                          key: product.id,
                          staticClass: "col-lg-4 mb-5 list-complete-item",
                          attrs: { "data-index": index },
                        },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "product-item",
                              attrs: {
                                to: {
                                  name: "product",
                                  params: {
                                    category: _vm.category.parent
                                      ? _vm.category.parent.slug
                                      : null,
                                    subcategory: _vm.category.slug,
                                    slug: product.slug,
                                  },
                                },
                              },
                            },
                            [
                              _c("div", { staticClass: "img-container" }, [
                                _c("img", {
                                  staticClass: "w-100",
                                  attrs: {
                                    src: product.featured_image,
                                    alt: "",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "product-info" }, [
                                _c(
                                  "div",
                                  { staticClass: "row align-items-center" },
                                  [
                                    _c("div", { staticClass: "col-12" }, [
                                      _c("strong", [
                                        _vm._v(_vm._s(product.brand)),
                                      ]),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c("strong", [
                                        _vm._v(_vm._s(product.name)),
                                      ]),
                                      _c("br"),
                                      _vm._v(
                                        "\n                                            " +
                                          _vm._s(product.short_description)
                                      ),
                                      _c("br"),
                                      _vm._v(" "),
                                      _c("span", { staticClass: "price" }, [
                                        _vm._v(
                                          "€ " +
                                            _vm._s(
                                              _vm.formatPrice(
                                                product.private_customer_price
                                              )
                                            )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e()
                }),
                0
              ),
              _vm._v(" "),
              _vm.results != []
                ? _c("div", { staticClass: "row justify-content-center" }, [
                    _c("div", { staticClass: "col-auto" }, [
                      _c(
                        "ul",
                        { staticClass: "pagination" },
                        [
                          _vm.pageCount > 1 && _vm.page > 1
                            ? _c(
                                "li",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.toPage(_vm.page - 1)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    <\n                                "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.page > 6 && _vm.pageCount > 10
                            ? _c("li", [
                                _vm._v(
                                  "\n                                    ...\n                                "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._l(_vm.pageCount, function (pageNumber) {
                            return (pageNumber > _vm.page - 6 &&
                              pageNumber < _vm.page + 6) ||
                              (pageNumber > _vm.page - 6 && pageNumber < 11) ||
                              (pageNumber < _vm.page + 6 &&
                                pageNumber > _vm.pageCount - 10)
                              ? _c(
                                  "li",
                                  {
                                    key: pageNumber,
                                    class:
                                      pageNumber === _vm.page
                                        ? "active-page"
                                        : "",
                                    on: {
                                      click: function ($event) {
                                        return _vm.toPage(pageNumber)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(pageNumber) +
                                        "\n                                "
                                    ),
                                  ]
                                )
                              : _vm._e()
                          }),
                          _vm._v(" "),
                          _vm.page < _vm.pageCount - 5 && _vm.pageCount > 10
                            ? _c("li", [
                                _vm._v(
                                  "\n                                    ...\n                                "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.pageCount > 1 && _vm.page < _vm.pageCount
                            ? _c(
                                "li",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.toPage(_vm.page + 1)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                    >\n                                "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }