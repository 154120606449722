var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-grow-1" },
    [
      _c("search-bar"),
      _vm._v(" "),
      _c("div", { staticClass: "row my-5" }, [
        _vm.order == null
          ? _c("div", { staticClass: "col-12" }, [
              _c(
                "h1",
                {
                  staticClass: "d-block text-center",
                  staticStyle: { "font-size": "22px" },
                },
                [_vm._v("Betaling verwerken")]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.order != null && _vm.order.payment_status == 3
          ? _c("div", { staticClass: "col-12 text-center" }, [
              _c(
                "h1",
                {
                  staticClass: "d-block text-center",
                  staticStyle: { "font-size": "22px" },
                },
                [_vm._v(_vm._s(_vm.__("webshop.Bedankt voor je bestelling")))]
              ),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  _vm._s(
                    _vm.__(
                      "webshop.We gaan zo snel mogelijk voor je aan de slag"
                    )
                  )
                ),
                _c("br"),
                _vm._v(
                  _vm._s(
                    _vm.__(
                      "webshop.Er werd een bestelbevestiging gestuurd naar je emailadres"
                    )
                  ) + "."
                ),
              ]),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }