var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 mt-4 mb-3" }, [
        _c("strong", { staticClass: "text-uppercase filter-title" }, [
          _vm._v(_vm._s(_vm.__("webshop.Categorieën"))),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12" }, [
        _c(
          "ul",
          _vm._l(_vm.categories, function (category) {
            return _c(
              "li",
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "main-category",
                        params: { slug: category.slug },
                        query: { page: 1, search: "" },
                      },
                    },
                  },
                  [_vm._v(_vm._s(category.name))]
                ),
                _vm._v(" "),
                category.children.length
                  ? _c(
                      "ul",
                      _vm._l(category.children, function (child) {
                        return _c(
                          "li",
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "category",
                                    params: {
                                      parent: category.slug,
                                      slug: child.slug,
                                    },
                                    query: { page: 1, search: "" },
                                  },
                                  exact: "",
                                },
                              },
                              [_vm._v(_vm._s(child.name))]
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }