import Checkout from "./views/Checkout";


require('./../bootstrap');
import Vue from 'vue'
import VueRouter from 'vue-router'
import VueFuse from 'vue-fuse'
import Vuex from 'vuex'
import axios from 'axios'
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
import VueToastr from '@deveodk/vue-toastr'
const geolib = require('geolib');

Vue.use(VueToastr, {
    defaultPosition: 'toast-top-right',
    defaultType: 'info',
    defaultTimeout: 1000
})
Vue.use(VueRouter)
Vue.use(VueFuse)
Vue.use(Vuex)
Vue.prototype.$http = axios
Vue.mixin(require('./mixins/trans'))
import App from './views/App'
//import Hello from './views/Hello'
import Home from './views/Home'
import Product from './views/Product'
import Category from './views/Category'
import Cart from './views/Cart'
import PaymentReturn from './views/PaymentReturn'
import BrandCategory from "./views/BrandCategory";

//import Navbar from './components/navbar';
//import { store } from "./store/store"
const store = new Vuex.Store({
    state: {
        products: [],
        StoreCart: [],
        Shipping: null,
    },
    getters: {
        products: (state) => state.products,
        StoreCart: (state) => state.StoreCart,
        totals: state => {
            let subtotal = 0;
            let total = 0;
            state.StoreCart.forEach(function(item){
                subtotal += item.product.private_customer_price * item.qty
            });
            total += subtotal
            if(state.Shipping) {
                total += state.Shipping
            }


            return {
                subtotal: subtotal,
                shipping: state.Shipping,
                total: total
            }
        },
        count: (state) => {
            return state.StoreCart.length
        }
    },

    mutations: {
        ADD_Item(state, payload) {
            //state.StoreCart = []
            let cartProduct = state.StoreCart.find((product) => product.id === payload.product.id);

            if (cartProduct) {
                cartProduct.qty+= payload.qty
            } else {
                state.StoreCart.push(
                    {
                        id: payload.product.id,
                        product: payload.product,
                        qty: payload.qty
                    }

                );
            }

        },

        CHANGE_Item(state, payload) {
            let cartProduct = state.StoreCart.find((product) => product.id === parseInt(payload.index));

            if (cartProduct) {
                cartProduct.qty = payload.qty
            }
        },

        REMOVE_Item(state, index) {
            state.StoreCart.splice(index, 1);
        },

        SET_shipping(state, country) {
            let subtotal = 0;
            state.StoreCart.forEach(function(item){
                subtotal += item.product.private_customer_price * item.qty
            });
            if(country === "België") {
                if(subtotal >= 49.95 ) {
                    state.Shipping = null
                } else {
                    state.Shipping = 5.25
                }
            } else if(country === "Nederland") {

                if(subtotal >= 59.95 ) {
                    state.Shipping = null
                } else {
                    state.Shipping = 6.95
                }

            }
        },

        CLEAR_Cart(state) {
            state.StoreCart = [];
        },
        initialiseStore(state) {
            // Check if the ID exists
            if(sessionStorage.getItem('kanters_webshop_cart')) {
                // Replace the state object with the stored item
                this.replaceState(
                    Object.assign(state, JSON.parse(sessionStorage.getItem('kanters_webshop_cart')))
                );
            }
            /*if(localStorage.getItem('kanters_webshop_cart')) {
                // Replace the state object with the stored item
                this.replaceState(
                    Object.assign(state, JSON.parse(localStorage.getItem('kanters_webshop_cart')))
                );
            }*/
        }
    },
    actions: {
        addItem(context, id) {
            context.commit("ADD_Item", id);
        },

        removeItem(context, index) {
            context.commit("REMOVE_Item", index);
        },

        changeItem(context, index) {
            context.commit("CHANGE_Item", payload);
        },
    },
    modules: {},

})

store.subscribe((mutation, state) => {
    // Store the state object as a JSON string
    //this.$session.set('kanters_webshop_cart', JSON.stringify(state));
    sessionStorage.setItem('kanters_webshop_cart', JSON.stringify(state));
});


Vue.mixin(require('./mixins/paths'));

//Vue.component('navbar', Navbar);
const router = new VueRouter({
    mode: 'history',
    //base: '/',
    linkActiveClass: 'active',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Category
        },
        {
            path: '/:locale/',
            name: 'home-loc',
            component: Category
        },
        {
            path: '/:locale/cart',
            name: 'cart',
            component: Cart
        },
        {
            path: '/:locale/betaling-verwerken',
            name: 'cart',
            component: PaymentReturn
        },
        {
            path: '/:locale/checkout',
            name: 'checkout',
            component: Checkout
        },
        {
            path: '/:locale/categorie/:slug',
            name: 'main-category',
            component: Category
        },
        {
            path: '/:locale/categorie/:parent/:slug',
            name: 'category',
            component: Category
        },
        {
            path: '/:locale/merk/:slug',
            name: 'main-brand-category',
            component: BrandCategory
        },
        {
            path: '/:locale/merk/:parent?/:slug',
            name: 'brand-category',
            component: BrandCategory
        },
        {
            path: '/:locale/producten/:category?/:subcategory?/:slug',
            name: 'product',
            component: Product
        },
    ],
});


const app = new Vue({
    el: '#app',
    data: {
        title : ''
    },
    components: {
        App,
    },
    router,
    store,

    axios,
    beforeCreate() {
        store.commit('initialiseStore');
    }
});

$(document).ready(function() {

    $(document).on('shown.bs.modal', '#myModal' ,function (e) {
        $("#myModal iframe").attr("src", $("#myModal iframe").attr("src")+ '&autoplay=true')
    })

    $(document).on('hide.bs.modal','#myModal',  function (e) {
        $("#myModal iframe").attr("src", $("#myModal iframe").attr("src").replace( '&autoplay=true', ''));
    })






// document ready
});
