var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row my-5" }, [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "h1",
          {
            staticClass: "d-block text-center",
            staticStyle: { "font-size": "22px" },
          },
          [_vm._v("Homepagina webshop")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }