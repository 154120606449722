<template>
    <div class="row my-5">
        <div class="col-12">
            <h1 class="d-block text-center" style="font-size: 22px">Homepagina webshop</h1>


        </div>
    </div>
</template>

<script>

export default {
    data ()  {
        return {

        }
    },
    components: {

    },
    methods: {

    },
    computed: {

    },
    created: function() {

    }


}
</script>
